
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_APP_USER, ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { IDateRangePicker, IInitialCampaign, IPushCampaign, IUser } from '@/utils/types'
import UserSelectionModal from '@/components/story/common/selectors/UserSelectionModal.vue'
import SettingTappableModal from '@/components/story/common/tappable-area/SettingTappableModal.vue'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: { DateRangeSelectorV2, SettingTappableModal, UserSelectionModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class ScenrioReportConditionSelector extends Vue {
  @Prop({})
  stories!: []

  @Prop({})
  appId!: string

  @Prop({ default: false })
  isTestMode = false

  modalVisible = false
  modalLoading = false
  defaultUsers = 'ユーザー'
  startDate = ''
  endDate = ''
  currentDateType = ''
  selectedStories = []
  currentTriggerType = ''
  selectedScenarios = []
  currentScenarioType = ''
  totalUsers = 0
  totalPages = 0
  totalFilterPages = 0
  perPages = 32
  users: IUser[] = []
  filterUsers: IUser[] = []
  selectedUsers: (string | undefined)[] = []
  firstLoad = false

  scenarios: {
    value: string
    label: string
  }[] = []

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  async onSelectedStories(val) {
    this.scenarios = []
    this.selectedScenarios = []
    if (val) {
      for (const storyId of val) {
        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)
        for (const p of pushes) {
          this.scenarios.push({
            value: p._id ?? '',
            label: p.title ?? '',
          })
        }
        const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
        if (initial._id !== '') {
          this.scenarios.push({
            value: initial._id ?? '',
            label: initial.title ?? '',
          })
        }
      }
    }
  }

  onSelectedScenarios(val) {
    console.log(val, 'onSelectedScenarios')
  }

  changeTriggerType(type) {
    if (type === this.currentTriggerType) {
      this.currentTriggerType = ''
    } else {
      this.currentTriggerType = type
    }
  }

  changeScenarioType(type) {
    if (type === this.currentScenarioType) {
      this.currentScenarioType = ''
    } else {
      this.currentScenarioType = type
    }
  }

  onOpenModal() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onSubmitModal(val: [], all) {
    if (all) {
      this.selectedUsers = []
    } else {
      this.selectedUsers = val
    }

    this.updateParams()
  }

  async loadUsers(filter) {
    this.modalLoading = true
    const response = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: filter,
    })
    if (response) {
      if (filter.state === 'selected' || filter.state === 'unselected') {
        this.filterUsers = response.items
        this.totalFilterPages = response.total_pages
      } else {
        this.users = response.items
        this.totalPages = response.total_pages
      }
    }
    this.modalLoading = false
  }

  changeDateRange(value) {
    this.currentDateType = ''
    this.dateRange = value

    this.updateParams()
  }

  @Watch('currentDateType')
  setDateRange() {
    if (!this.firstLoad) {
      return
    }
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    this.updateParams()
  }

  @Watch('selectedStories')
  @Watch('selectedScenarios')
  @Watch('currentTriggerType')
  @Watch('currentScenarioType')
  updateParams() {
    console.log('updateParams')

    const params = {
      start_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      end_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      stories: this.selectedStories,
      scenarios: this.selectedScenarios,
      trigger_type: this.currentTriggerType,
      scenario_type: this.currentScenarioType,
      users: this.selectedUsers,
    }
    this.$emit('update:queryParams', params)
  }

  @Watch('isTestMode')
  async onChangeTestMode() {
    console.log('onChangeTestMode')

    this.selectedUsers = []

    const all = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {},
    })

    if (all) {
      this.totalUsers = all.total_items
    }

    const filter = {
      page: 1,
      per_page: this.perPages,
    }
    await this.loadUsers(filter)
    this.updateParams()
  }

  async created() {
    this.currentDateType = 'thisMonth'
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    await this.onChangeTestMode()
    this.firstLoad = true
  }
}
