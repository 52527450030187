<template>
  <div class="row q-col-gutter-md q-pb-md">
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">期間選択</div>
          <DateRangeSelectorV2
            v-model="dateRange"
            :disable="false"
            :display-label="false"
            opens="right"
            @update:onChangeDateRange="changeDateRange"
          ></DateRangeSelectorV2>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今日"
                class="full-width"
                @click="changeDate('today')"
                :class="currentDateType === 'today' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="昨日"
                class="full-width"
                @click="changeDate('yesterday')"
                :class="currentDateType === 'yesterday' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今週"
                class="full-width"
                @click="changeDate('thisWeek')"
                :class="currentDateType === 'thisWeek' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="前週"
                class="full-width"
                @click="changeDate('lastWeek')"
                :class="currentDateType === 'lastWeek' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今月"
                class="full-width"
                @click="changeDate('thisMonth')"
                :class="currentDateType === 'thisMonth' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="前月"
                class="full-width"
                @click="changeDate('lastMonth')"
                :class="currentDateType === 'lastMonth' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">ストーリー選択</div>
          <div class="row">
            <q-select
              outlined
              dense
              option-value="_id"
              option-label="story_name"
              multiple
              emit-value
              map-options
              v-model="selectedStories"
              :options="stories"
              label="ストーリー"
              class="full-width"
              @update:model-value="onSelectedStories"
              clearable
            />
          </div>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="デフォルト"
                class="full-width"
                @click="changeTriggerType('default')"
                :class="currentTriggerType === 'default' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="ポップアップ"
                class="full-width"
                @click="changeTriggerType('popup_deliver')"
                :class="currentTriggerType === 'popup_deliver' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="QR/URL"
                class="full-width"
                @click="changeTriggerType('qr_code')"
                :class="currentTriggerType === 'qr_code' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="セグメント"
                class="full-width"
                @click="changeTriggerType('audience')"
                :class="currentTriggerType === 'audience' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="一斉配信"
                class="full-width"
                @click="changeTriggerType('all')"
                :class="currentTriggerType === 'all' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">シナリオ選択</div>
          <div class="row">
            <q-select
              outlined
              dense
              option-value="value"
              option-label="label"
              multiple
              emit-value
              map-options
              v-model="selectedScenarios"
              :options="scenarios"
              label="シナリオ"
              class="full-width"
              @update:model-value="onSelectedScenarios"
              clearable
            />
          </div>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="初回"
                class="full-width"
                @click="changeScenarioType('initial')"
                :class="currentScenarioType === 'initial' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="プッシュ"
                class="full-width"
                @click="changeScenarioType('push')"
                :class="currentScenarioType === 'push' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">ユーザー選択</div>
          <div class="row" @click="onOpenModal">
            <q-input readonly outlined dense v-model="defaultUsers" class="full-width">
              <template v-slot:append>
                <q-icon name="filter_none" />
              </template>
            </q-input>
          </div>
          <div class="row justify-end items-baseline q-gutter-xs q-mt-xs">
            <span class="text-subtitle2 text-grey-8 text-bold">
              {{ selectedUsers.length ? selectedUsers.length : totalUsers }} / {{ totalUsers }}
            </span>
            <span class="text-grey-8" style="font-size: 8px">名を選択中</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
  <UserSelectionModal
    :modalVisible="modalVisible"
    :users="users"
    :filterUsers="filterUsers"
    :totalUsers="totalUsers"
    :totalPages="totalPages"
    :totalFilterPages="totalFilterPages"
    :perPages="perPages"
    :loading="modalLoading"
    @update:closeModal="onCloseModal"
    @update:submitUsers="onSubmitModal"
    @update:loadUsers="loadUsers"
    @update:filterUsers="loadUsers"
  ></UserSelectionModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_APP_USER, ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { IDateRangePicker, IInitialCampaign, IPushCampaign, IUser } from '@/utils/types'
import UserSelectionModal from '@/components/story/common/selectors/UserSelectionModal.vue'
import SettingTappableModal from '@/components/story/common/tappable-area/SettingTappableModal.vue'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: { DateRangeSelectorV2, SettingTappableModal, UserSelectionModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class ScenrioReportConditionSelector extends Vue {
  @Prop({})
  stories!: []

  @Prop({})
  appId!: string

  @Prop({ default: false })
  isTestMode = false

  modalVisible = false
  modalLoading = false
  defaultUsers = 'ユーザー'
  startDate = ''
  endDate = ''
  currentDateType = ''
  selectedStories = []
  currentTriggerType = ''
  selectedScenarios = []
  currentScenarioType = ''
  totalUsers = 0
  totalPages = 0
  totalFilterPages = 0
  perPages = 32
  users: IUser[] = []
  filterUsers: IUser[] = []
  selectedUsers: (string | undefined)[] = []
  firstLoad = false

  scenarios: {
    value: string
    label: string
  }[] = []

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  async onSelectedStories(val) {
    this.scenarios = []
    this.selectedScenarios = []
    if (val) {
      for (const storyId of val) {
        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)
        for (const p of pushes) {
          this.scenarios.push({
            value: p._id ?? '',
            label: p.title ?? '',
          })
        }
        const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
        if (initial._id !== '') {
          this.scenarios.push({
            value: initial._id ?? '',
            label: initial.title ?? '',
          })
        }
      }
    }
  }

  onSelectedScenarios(val) {
    console.log(val, 'onSelectedScenarios')
  }

  changeTriggerType(type) {
    if (type === this.currentTriggerType) {
      this.currentTriggerType = ''
    } else {
      this.currentTriggerType = type
    }
  }

  changeScenarioType(type) {
    if (type === this.currentScenarioType) {
      this.currentScenarioType = ''
    } else {
      this.currentScenarioType = type
    }
  }

  onOpenModal() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onSubmitModal(val: [], all) {
    if (all) {
      this.selectedUsers = []
    } else {
      this.selectedUsers = val
    }

    this.updateParams()
  }

  async loadUsers(filter) {
    this.modalLoading = true
    const response = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: filter,
    })
    if (response) {
      if (filter.state === 'selected' || filter.state === 'unselected') {
        this.filterUsers = response.items
        this.totalFilterPages = response.total_pages
      } else {
        this.users = response.items
        this.totalPages = response.total_pages
      }
    }
    this.modalLoading = false
  }

  changeDateRange(value) {
    this.currentDateType = ''
    this.dateRange = value

    this.updateParams()
  }

  @Watch('currentDateType')
  setDateRange() {
    if (!this.firstLoad) {
      return
    }
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    this.updateParams()
  }

  @Watch('selectedStories')
  @Watch('selectedScenarios')
  @Watch('currentTriggerType')
  @Watch('currentScenarioType')
  updateParams() {
    console.log('updateParams')

    const params = {
      start_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      end_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      stories: this.selectedStories,
      scenarios: this.selectedScenarios,
      trigger_type: this.currentTriggerType,
      scenario_type: this.currentScenarioType,
      users: this.selectedUsers,
    }
    this.$emit('update:queryParams', params)
  }

  @Watch('isTestMode')
  async onChangeTestMode() {
    console.log('onChangeTestMode')

    this.selectedUsers = []

    const all = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {},
    })

    if (all) {
      this.totalUsers = all.total_items
    }

    const filter = {
      page: 1,
      per_page: this.perPages,
    }
    await this.loadUsers(filter)
    this.updateParams()
  }

  async created() {
    this.currentDateType = 'thisMonth'
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    await this.onChangeTestMode()
    this.firstLoad = true
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0;
}
.text-subtitle2 {
  font-size: 0.75rem;
}
.q-card {
  height: 100%;
}
.cursor-pointer {
  font-size: 15px;
  padding-top: 12px;
}
:deep(.q-field--outlined .q-field__control) {
  padding: 0 8px;
}
:deep(.q-select--multiple .q-field__native > span) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
:deep(.q-btn.full-width span) {
  font-size: 8px;
  line-height: 1.25rem;
  white-space: nowrap;
}
:deep(.q-radio--dense .q-radio__label) {
  font-size: 12px;
  line-height: 1.5rem;
  margin-top: 8px;
}
:deep(.smaller-input) {
  font-size: initial;
}
:deep(.date-select .q-field__append) {
  font-size: 24px;
}
:deep(.q-field--outlined.q-field--readonly .q-field__control:before) {
  border-style: solid;
}
</style>
